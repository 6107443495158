import React from "react";
import logo from "../../assets/Image/logo.svg";

function ThankYou() {
    return (
        <>
            <div className="bgadminmob">
                <section className="bgloginsuper-admin bg_otp m-top25 commonipadbg loginipad-bg">
                    <div className="container">
                        <div className="logo-w">
                            {/* <Link to="/signin"> */}
                                <img src={logo} className="logo" alt="logo" />
                            {/* </Link> */}
                        </div>
                        <div className="row top63">
                            <div className="forgot">
                                <div className="icon-aut">
                                    <div className="otp-heading">Thank You!</div>
                                    <h4 className="otp-heading">Your scan has been successfully completed.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="Powered-by">© Powered By Neocogita</p>
                </section>

            </div>
        </>
    )
}
export default ThankYou;