import React, { useEffect, useState, useParams } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { GoNoGoResult } from "./GoNoGoResult";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import GoNoGoResultImg from "../../../../assets/Image/GoNoGoResultImg.svg";
import { submitUserActivityData } from '../common/utilty';
import LazyLoader from "../../../common/lazyLoader";


export const GoNoGoResume = ({ props }) => {

    const { round, totalTime, resetRound, togglePause, startTimer, timeRemaining, avgAccuracy, params, totalAvgResponseTime, accuracyCurrentRound, averageResponseTime, free } = props;

    const [t, i18n] = useTranslation("common");
    const companyId = getLocalStoarge('company_id');
    const status = "in-complete";
    const [isLoading, setIsLoading] = useState(true);
    const component_id = parseInt(params.component_id);
    const repeat_time = parseInt(params.repeat_time);
    const day_sequence = parseInt(params.day_sequence);
    const activity_id = parseInt(params.activity_id);


    useEffect(() => {
        togglePause();
    }, [])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    const avgScore = () => {
        return (((avgAccuracy * round) + (accuracyCurrentRound)) / (round + 1))
    }

    const avgResponseTime = () => {
        return (((totalAvgResponseTime * round) + (parseInt(averageResponseTime))) / parseInt(round + 1));
    }

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: component_id,
        day_sequence: day_sequence,
        repeat_time: repeat_time,
        activity_id: activity_id,
        status: status,
        activity_score: avgScore(),
        activity_play_time: ((totalTime * 60) - timeRemaining),
        activity_round_no: round + 1,
        total_avg_response_time: avgResponseTime()
    })

    useEffect(() => {
        (timeRemaining > 0 && !free) ? submitUserActivityData(payload,setIsLoading) : null
    }, [payload])

    const reset = (fun) => {
        resetRound();
    }


    return (
        <>
            {
                (timeRemaining > 0) ?
                    <>
                        {(isLoading) ? <LazyLoader /> : null}
                        <section className="reaction-speed-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stepsin stpelog">
                                            <Link to="dashboard">
                                                <img src={logo} className="logo-steps" alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="reaction-speed-result">
                                            <div className="col-md-6">
                                                <div className="reaction-speed-result-1">
                                                    <h2>{t("user.activity.go_no_go.result_heading")}</h2>
                                                    <p>
                                                        {t("user.activity.go_no_go.result_message").replace('percent', `${Math.round(parseInt((accuracyCurrentRound * 100)))}%`).replace('averageTime', `${Math.round(averageResponseTime)} ms`)}
                                                    </p>
                                                    <div className="row">
                                                        <div className="reaction-speed-result-1-1">
                                                            <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
                                                                <p className="reaction-speed-result-reponse-bar">
                                                                    <CircularProgressbar value={accuracyCurrentRound} maxValue={1} strokeWidth={12} styles={buildStyles({
                                                                        pathColor: (Math.round(parseInt(accuracyCurrentRound * 100))) >= 60 ? '#9ECB61' : '#B02418',
                                                                    })}
                                                                    /></p>
                                                            </div>
                                                            <div className="col-md-6 reaction-speed-result-accuracy">
                                                                <h6>{Math.round(parseInt(accuracyCurrentRound * 100))}%</h6>
                                                                <p>{t("user.activity.go_no_go.result_accuracy")}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="reaction-speed-result-response">{parseInt(Math.round(averageResponseTime))}ms</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="reaction-speed-result-response-text">{t("user.activity.go_no_go.result_response")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="reaction-speed-result-2 r-statistiche">
                                                    <img src={GoNoGoResultImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 bw-resume-buttons">
                                            <button className="btn btn-primary btnNext" onClick={(event) => { reset() }}>{t("user.activity.executive_function.continue")}</button>
                                            <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                                                <button className="btn btn-primary btnNext-act">
                                                    {t("user.activity.reaction_speed.exit")}
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <GoNoGoResult props={{ "round": round, "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime, "accuracyCurrentRound": accuracyCurrentRound, "averageResponseTime": averageResponseTime, "free":free }} />
            }
        </>
    )
}