import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import Beep from "../../../../assets/Audio/beep.mp3";
import WrongBeep from "../../../../assets/Audio/wrongBeep.wav";
import click from "../../../../assets/Image/click.svg";
import wait from "../../../../assets/Image/wait.svg";
import { ReactionSpeedResume } from "./ReactionSpeedResume";
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from "../../../apiConnection/getRequest";
import LazyLoader from "../../../common/lazyLoader";

export const ReactionSpeed = (props) => {
    const history = useHistory();
    const [t, i18n] = useTranslation("common");
    const [showClick, setShowClick] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [step, setStep] = useState(0);
    const [maxSteps, setMaxSteps] = useState(10);
    const [totalTime, setTotalTime] = useState()
    const [timeRemaining, setTimeRemaining] = useState();
    const [isPaused, setIsPaused] = useState(false);
    const [round, setRound] = useState();
    const [accuracyCurrentRound, setAccuracyCurrentRound] = useState(0);
    const [avgAccuracy, setAvgAccuracy] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [activityStatus, setActivityStatus] = useState("");
    const [responseTimes, setResponseTimes] = useState([]);
    const [startTime, setStartTime] = useState();
    const [averageResponseTime, setAverageResponseTime] = useState();
    const [totalAvgResponseTime, setTotalAvgResponseTime] = useState();
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const [free, setFree] = useState(props?.match?.params?.free);
    const theme = JSON.parse(localStorage.getItem("theme"));
    const [stepComplete, setIsStepComplete] = useState(false);

    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])


    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        if (free) {
            setTotalTime(300);
            setTimeRemaining(0);
            setRound(1);
            setAvgAccuracy(0);
            setActivityStatus("in-complete");
            setTotalAvgResponseTime(0);
            // setAverageResponseTime(0);
            setIsLoading(false);
        } else {
            getReactionSpeedUserData();
        }
    }, [])

    useEffect(() => {
         setMaxSteps(Math.floor(Math.random() * (32 - 25 + 1)) + 25);
    }, [])


    const getReactionSpeedUserData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            if (refreshToken) {
                const getUserActivityData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ACTIVITY_STATUS}?repeat_time=${repeat_time}&component_id=${component_id}&day_sequence=${day_sequence}&activity_id=${activity_id}`,
                    "",
                    "",
                    ""
                );

                if (getUserActivityData && getUserActivityData.status === 200) {
                    setIsLoading(false);
                    if (getUserActivityData?.data?.data?.activity_data) {
                        let dataObj = getUserActivityData?.data?.data?.activity_data;
                        setTotalTime(dataObj.activity_total_data[0].activity_duration);
                        setTimeRemaining(((dataObj.activity_total_data[0].activity_duration) * 60) - (dataObj.activity_play_time));
                        setRound(dataObj.activity_round_no);
                        setAvgAccuracy(dataObj.activity_score);
                        setActivityStatus(dataObj.status);
                        setTotalAvgResponseTime(dataObj.total_avg_response_time);
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }

    useEffect(() => {
        let timerId;
        if (!isPaused && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timeRemaining, isPaused]);

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    const startTimer = () => {
        setIsPaused(false);
    }

    const resetRound = () => {
        setStep(0);
        setClickCount(0)
        setRound(round + 1);
        setAccuracyCurrentRound(0);
        getReactionSpeedUserData();
        setIsStepComplete(false)
    }

    useEffect(() => {
        if (free) {
            setAvgAccuracy(accuracyCurrentRound)
        }
    }, [accuracyCurrentRound])


    useEffect(() => {
        if (showClick && step <= maxSteps) {
            setStartTime(Date.now());
        } else {
            setStartTime(null);
        }
    }, [showClick])

    const handleButtonClick = () => {
        if (showClick && step <= maxSteps) {
            setClickCount(clickCount + 1);
            setShowClick(false);
            setStep(step + 1);

            const endTime = Date.now();
            const responseTime = endTime - startTime;
            setResponseTimes([...responseTimes, responseTime]);
        }
    };

    useEffect(() => {
        setAverageResponseTime((
            responseTimes.reduce((acc, curr) => acc + curr, 0) / responseTimes.length
        ).toFixed(0));
    }, [responseTimes])

    useEffect(() => {
        if (free) {
            setTotalAvgResponseTime(averageResponseTime);
        } else {
            setTotalAvgResponseTime(((totalAvgResponseTime * round) + (parseInt(averageResponseTime))) / (round + 1));
        }
    }, [averageResponseTime])


    useEffect(() => {
        if(clickCount > 0 && step <= maxSteps){
            setAccuracyCurrentRound((clickCount / maxSteps));
        }

    }, [clickCount])


    useEffect(() => {
        //
        if (step <= maxSteps && !isLoading) {
            const timer = setTimeout(() => {
                setShowClick(!showClick);
                if (showClick === true) {
                    setStep(step + 1);
                }
            }, getRandomTime(showClick));
            return () => clearTimeout(timer);
        }
    }, [step, showClick, isLoading]);

    useEffect(() => {
        if (step <= maxSteps) {
            setShowClick(false);
        }
    }, [step]);

    const getRandomTime = (isClickDiv) => {
        // Set different time ranges for WaitDiv and ClickDiv
        const minTime = isClickDiv ? 700 : 1000; // 250 milliSecond for ClickDiv, 2 seconds for WaitDiv
        const maxTime = isClickDiv ? 700 : 6000; // 700 milliSeconds for ClickDiv, 5 seconds for WaitDiv
        return Math.floor(Math.random() * (maxTime - minTime + 1) + minTime);
    };


    const handleClick = (val) => {
        if(step <= maxSteps){
            if (val == 'Beep') {
                const audio = new Audio(Beep);
                audio.play();
            }
    
            if (val == 'WrongBeep') {
                const audio = new Audio(WrongBeep);
                audio.play();
            }
        }
    }

    const handleUserKeyPress = useCallback(event => {
        console.log("space bar clicked");
        const { keyCode } = event;
        if (keyCode === 32) {
            if (showClick) {
                handleButtonClick();
                handleClick('Beep')
            } else {
                handleClick('WrongBeep')
            }
        }
    })

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };

    }, [handleUserKeyPress]);

    useEffect(() => {
        if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && !free) {
            history.push(`/reaction-speed-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`);
        }
        else if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && free) {
            history.push(`/reaction-speed-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}${free}`);
        }
    }, [activityStatus])

    useEffect(() => {
        if (step >= maxSteps) {
            setIsStepComplete(true); // Show Resume page only after final calculation
        }
    }, [step]);

    return (
        <>
            {
                !stepComplete ?
                    <>
                        {(isLoading) ? <LazyLoader /> : null}
                        <section className="reaction-speed-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stepsin stpelog">
                                            <Link to="dashboard">
                                                <img src={logo} className="logo-steps" alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="btnNext-top">
                                            {free ?
                                                <Link to={`/reaction-speed-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                                    <button
                                                        className="btn btn-primary  btnNext"
                                                    >
                                                        {t("user.activity.reaction_speed.back")}
                                                    </button>
                                                </Link>
                                                :
                                                <Link to={`/reaction-speed-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                    <button
                                                        className="btn btn-primary  btnNext"
                                                    >
                                                        {t("user.activity.reaction_speed.back")}
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="reaction-speed-p">
                                            <p className="reaction-speed-p-1">{t("user.activity.reaction_speed.intro_heading")}</p>
                                            <p className="reaction-speed-p-2" dangerouslySetInnerHTML={{ __html: t("user.activity.reaction_speed.index_message") }}></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="reaction-speed-step">
                                            <p>{step + 1}/{maxSteps}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="reaction-speed-main dark-reaction-speed">
                                            {showClick ? <p onClick={handleButtonClick}>
                                                <span className="reaction-img1">
                                                    <img src={click} alt="logo" onClick={() => handleClick('Beep')} />
                                                </span>
                                            </p>
                                                :
                                                <p>
                                                    <span className="reaction-img2">
                                                        <img src={wait} alt="logo" onClick={() => handleClick('WrongBeep')} />
                                                    </span>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <ReactionSpeedResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "startTimer": startTimer, "timeRemaining": timeRemaining, "avgAccuracy": avgAccuracy, "params": props.match.params, "totalAvgResponseTime": totalAvgResponseTime, "free": free,"step":step,"maxSteps":maxSteps,"accuracyCurrentRound":accuracyCurrentRound }} />
            }
        </>
    );
}

