import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { getCognitivePrePost, getLocalStoarge } from '../../../common/allfunction';
import LazyLoader from '../../../common/lazyLoader';


const CognitiveActivityWisePrePost1 = (props) => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [workingMemory, setWorkingMemory] = useState([]);
    const [planning, setPlanning] = useState([]);
    const [reactionSpeed, setReactionSpeed] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        getPrePostData();
    }, [props.repeatTime]);


    const getPrePostData = async () => {
        const data = await getCognitivePrePost(props.repeatTime);
        setIsLoading(false);
        setReactionSpeed(data?.reactionSpeedData);
        setWorkingMemory(data?.WorkingMemoryData);
        setPlanning(data?.PlanningData);
    }

    return (
        <div>
            {isLoading ? <LazyLoader /> : null}
            <div className='second-top-heading mt-3'>
                <div className='col-12'>
                    <h2 className='second-page-heading'>{t('user.activityReport.details_pre_post')}</h2>
                    <p className='pre-post-heading-description' dangerouslySetInnerHTML={{ __html: t('user.activityReport.details_description') }}></p>
                </div>
            </div>
            <div className='pre-post-activity-name-desc'>
                <div className="row m-0">
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name min-second-activity ms-0'>{t('user.activityReport.Cognitive-activity-name1')}</h5>
                        <p className='second-page-activity-para ms-0'>{t('user.activityReport.Cognitive-activity1-description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(reactionSpeed?.length > 0 && Boolean((reactionSpeed[0]))) ? (reactionSpeed[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ 
                                        left: reactionSpeed ? `${(() => {
                                        let value = reactionSpeed[0];
                                        if (value <= 250) return 0; 
                                        if (value <= 350) return ((value - 250) / 100) * 50; 
                                        if (value <= 450) return 50 + ((value - 350) / 100) * 14.28;
                                        if (value <= 550) return 64.28 + ((value - 450) / 100) * 14.28;
                                        return 78.56 + ((value - 550) / 100) * 21.52;
                                        })()}%` : null 
                                    }} 
                                    className='vertical-line'
                                ></span>                                
                                <div className="progress-bar color-light-sky" style={{ width: "50%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "14.28%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "14.28%" }}></div>
                                <div className="progress-bar color-red" style={{ width: "21.52%" }}></div>
                            </div>
                            <div className='d-flex'>
                                <p className='progress-score-name' style={{ width: "50%" }}>{t('user.activityReport.Cognitive_progress_bar_step1')}</p>
                                <p className='progress-score-name' style={{ width: "14.28%" }}>{t('user.activityReport.Cognitive_progress_bar_step2')}</p>
                                <p className='progress-score-name' style={{ width: "14.28%" }}>{t('user.activityReport.Cognitive_progress_bar_step3')}</p>
                                <p className='progress-score-name' style={{ width: "21.52%" }}>{t('user.activityReport.Cognitive_progress_bar_step4')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(reactionSpeed?.length > 0 && Boolean(reactionSpeed[1])) ? (reactionSpeed[1]) : 0} `}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ 
                                        left: reactionSpeed ? `${(() => {
                                        let value = reactionSpeed[1];
                                        if (value <= 250) return 0; 
                                        if (value <= 350) return ((value - 250) / 100) * 50; 
                                        if (value <= 450) return 50 + ((value - 350) / 100) * 14.28;
                                        if (value <= 550) return 64.28 + ((value - 450) / 100) * 14.28;
                                        return 78.56 + ((value - 550) / 100) * 21.52;
                                        })()}%` : null 
                                    }} 
                                    className='vertical-line'
                                ></span>   
                                <div className="progress-bar color-light-sky" style={{ width: "50%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "14.28%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "14.28%" }}></div>
                                <div className="progress-bar color-red" style={{ width: "21.52%" }}></div>
                            </div>
                            <div className='d-flex'>
                                <p className='progress-score-name' style={{ width: "50%" }}>{t('user.activityReport.Cognitive_progress_bar_step1')}</p>
                                <p className='progress-score-name' style={{ width: "14.28%" }}>{t('user.activityReport.Cognitive_progress_bar_step2')}</p>
                                <p className='progress-score-name' style={{ width: "14.28%" }}>{t('user.activityReport.Cognitive_progress_bar_step3')}</p>
                                <p className='progress-score-name' style={{ width: "21.52%" }}>{t('user.activityReport.Cognitive_progress_bar_step4')}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p className='calculate-percent'>{`${(reactionSpeed?.length > 0 && Boolean((reactionSpeed[2]))) ? (reactionSpeed[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>
                            <ul className='cognitive-pre-post-list-info'>
                                <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.Cognitive-info-list-1') }}></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity-name2')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity2-description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(workingMemory?.length > 0 && Boolean(workingMemory[0])) ? (workingMemory[0].toFixed(1)) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                            <span
                                style={{
                                    left: workingMemory
                                    ? `${(() => {
                                        const value = workingMemory[0];
                                        if (value < 3) {
                                            return Math.min((value / 3) * 33.33, 33.33);
                                        } else if (value >= 3 && value < 5) {
                                            return 33.33 + Math.min(((value - 3) / 2) * 33.33, 33.33);
                                        } else if (value >= 5 && value <= 7) {
                                            return 66.66 + Math.min(((value - 5) / 2) * 16.67, 16.67);
                                        } else {
                                            return 83.33 + Math.min(((value - 7) / 1) * 16.67, 16.67);
                                        }
                                        })()}%`
                                    : null
                                }}
                                className="vertical-line"
                                ></span>
                                <div className="progress-bar color-red" style={{ width: "33.33%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "33.33%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "16.67%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "16.67%" }}></div>
                            </div>
                            <div className='d-flex'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.Cognitive_progress_bar_step5')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.Cognitive_progress_bar_step6')}</p>
                                <p className='progress-score-name' style={{ width: "16.67%" }}>{t('user.activityReport.Cognitive_progress_bar_step7')}</p>
                                <p className='progress-score-name' style={{ width: "16.67%" }}>{t('user.activityReport.Cognitive_progress_bar_step8')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(workingMemory?.length > 0 && Boolean(workingMemory[1])) ? (workingMemory[1].toFixed(1)) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                            <span
                                style={{
                                    left: workingMemory
                                    ? `${(() => {
                                        const value = workingMemory[1];
                                        if (value < 3) {
                                            return Math.min((value / 3) * 33.33, 33.33);
                                        } else if (value >= 3 && value < 5) {
                                            return 33.33 + Math.min(((value - 3) / 2) * 33.33, 33.33);
                                        } else if (value >= 5 && value <= 7) {
                                            return 66.66 + Math.min(((value - 5) / 2) * 16.67, 16.67);
                                        } else {
                                            return 83.33 + Math.min(((value - 7) / 1) * 16.67, 16.67);
                                        }
                                        })()}%`
                                    : null
                                }}
                                className="vertical-line"
                                ></span>
                                <div className="progress-bar color-red" style={{ width: "33.33%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "33.33%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "16.67%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "16.67%" }}></div>
                            </div>
                            <div className='d-flex'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.Cognitive_progress_bar_step5')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.Cognitive_progress_bar_step6')}</p>
                                <p className='progress-score-name' style={{ width: "16.67%" }}>{t('user.activityReport.Cognitive_progress_bar_step7')}</p>
                                <p className='progress-score-name' style={{ width: "16.67%" }}>{t('user.activityReport.Cognitive_progress_bar_step8')}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p className='calculate-percent'>{`${(workingMemory?.length > 0 && Boolean(workingMemory[2])) ? (workingMemory[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                            <ul className='pre-post-list-info'>
                                <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.Cognitive-info-list-2') }}></li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity-name3')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity3-description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(planning?.length > 0 && Boolean(planning[0])) ? planning[0] : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: planning ? `${(((100 * planning[0]) / 36) > 100) ? 100 : ((100 * planning[0]) / 36)}%` : null }} className='vertical-line'></span>
                                {/* <span style={{ left: planning ? `100%` : null }} className='vertical-line'></span> */}
                                <div className="progress-bar color-red" style={{ width: "41.66%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "25%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "13.88%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "19.46%" }}></div>
                            </div>
                            <div className='d-flex'>
                                <p className='progress-score-name'  style={{ width: "41.66%" }}>{t('user.activityReport.Cognitive_progress_bar_step9')}</p>
                                <p className='progress-score-name' style={{ width: "25%" }}>{t('user.activityReport.Cognitive_progress_bar_step10')}</p>
                                <p className='progress-score-name' style={{ width: "13.88%" }}>{t('user.activityReport.Cognitive_progress_bar_step11')}</p>
                                <p className='progress-score-name' style={{ width: "19.46%" }}>{t('user.activityReport.Cognitive_progress_bar_step12')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(planning?.length > 0 && Boolean(planning[1])) ? planning[1] : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: planning ? `${(((100 * planning[1]) / 36) > 100) ? 100 : ((100 * planning[1]) / 36)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "41.66%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "25%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "13.88%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "19.46%" }}></div>
                            </div>
                            <div className='d-flex'>
                                <p className='progress-score-name' style={{ width: "41.66%" }}>{t('user.activityReport.Cognitive_progress_bar_step9')}</p>
                                <p className='progress-score-name' style={{ width: "25%" }}>{t('user.activityReport.Cognitive_progress_bar_step10')}</p>
                                <p className='progress-score-name' style={{ width: "13.88%" }}>{t('user.activityReport.Cognitive_progress_bar_step11')}</p>
                                <p className='progress-score-name' style={{ width: "19.46%" }}>{t('user.activityReport.Cognitive_progress_bar_step12')}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p className='calculate-percent'>{`${(planning?.length > 0 && Boolean(planning[2])) ? (planning[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                            <ul className='pre-post-list-info'>
                                <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.Cognitive-info-list-3') }}></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CognitiveActivityWisePrePost1
