import React, { useState, useEffect } from 'react'
import { WorkingMemory } from './WorkingMemory';
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { WorkingMemoryResult } from "./WorkingMemoryResult";
import LazyLoader from "../../../common/lazyLoader";

export const WorkingMemoryResume = ({ props }) => {

  const { round, totalTime, resetRound, togglePause, startTimer, timeRemaining, avgAccuracy, params, totalAvgResponseTime, totalAvgCongruentResponseTime , totalAvgIncongruentResponseTime, totalAvgNeutralResponseTime ,free } = props;

  // console.log("props working memory resume", round);
  // console.log("totalTime", totalTime * 60);
  // console.log("timeRemaining-2 props", timeRemaining);
  const [t, i18n] = useTranslation("common");
  const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
  const [status, setStatus] = useState("in-complete");
  const [activityPlayTime, setActivityPlayTime] = useState((totalTime * 60) - timeRemaining);
  const component_id = parseInt(params.component_id);
  const repeat_time = parseInt(params.repeat_time);
  const day_sequence = parseInt(params.day_sequence);
  const activity_id = parseInt(params.activity_id);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    // console.log("useeffect-1");
    togglePause();
    // checkStatus();
  }, [])

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  const [payload, setPayload] = useState({
    company_id: companyId,
    component_id: component_id,
    day_sequence: day_sequence,
    repeat_time: repeat_time,
    activity_id: activity_id,
    status: status,
    activity_score: avgAccuracy,
    activity_play_time: ((totalTime * 60) - timeRemaining),
    activity_round_no: round + 1,
    total_avg_response_time: totalAvgResponseTime
  });

  useEffect(() => {
    //We have total 3 rounds, If round is greater than 3 our result page will be called
    if (round < 2  && !free) {
      submitUserActivityData(payload)
    }
  }, [payload]);



  const submitUserActivityData = async (payload_1) => {
    // alert("resume page api called");

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );

      // console.log("refresh_token", refreshToken);
      if (refreshToken) {
        const result = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
          payload_1,
          "",
          ""
        );
        // console.log("api_submit_result", result);
        if (result) {
          if (result.status === 200) {
            // Handle status 200
            // console.log("api_submit_result_1", result);
          } else if (result.status === 203) {
            // Handle status 203
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error)
    }
  }

  const reset = (fun) => {
    // console.log("fun_1", resetRound)
    resetRound();
  }

  const startCountdown = () => {
    // console.log("count down started")
    startTimer();
  }


  return (
    <>
      {
        //Here you have to implement logic round >2
        (round < 2 && !free) ?
          <>
            {(isLoading) ? <LazyLoader /> : null}
            <section className="reaction-speed-section align-excutive">
              <div className="container-fluid">
                <div className="row">
                  <div className="stepsin stpelog">
                    <Link to="dashboard">
                      <img src={logo} className="logo-steps" alt="logo" />
                    </Link>
                  </div>
                  <div className="btnNext-top">
                    {free ? 
                    <Link to={`/working-memory-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                      <button
                        className="btn btn-primary reaction-speed-btnEsci"
                      >
                        {t("user.activity.reaction_speed.back")}
                      </button>
                    </Link>
                    :
                    <Link to={`/working-memory-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                      <button
                        className="btn btn-primary reaction-speed-btnEsci"
                      >
                        {t("user.activity.reaction_speed.back")}
                      </button>
                    </Link>
                    }
                  </div>


                  <div className="col-md-12">
                    <div className="working-function-resume">
                      <h2>{t("user.activity.executive_function.resume_heading")}</h2>
                      <p>{t("user.activity.executive_function.resume_message")}</p>
                      <button className="btn btn-primary btnNext" onClick={event => { reset(); startCountdown() }}>{t("user.activity.executive_function.continue")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
          :
          <WorkingMemoryResult props={{ "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime, "round": round, "timeRemaining": timeRemaining ,'free':free}} />
      }
    </>
  )
}
