import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { ExecutiveFunctionResult } from './ExecutiveFunctionResult';
import LazyLoader from "../../../common/lazyLoader";

export const ExecutiveFunctionResume = ({ props }) => {
    const { round, totalTime, resetRound, togglePause, startTimer, timeRemaining, avgAccuracy, params, totalAvgResponseTime, totalAvgCongruentResponseTime , totalAvgIncongruentResponseTime, totalAvgNeutralResponseTime,free ,maxSteps ,step,accuracyCurrentRound} = props;

    // console.log("props executive function", props);
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(true);
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [status, setStatus] = useState("in-complete");
    const [activityPlayTime, setActivityPlayTime] = useState((totalTime * 60) - timeRemaining)
    // console.log("props", props)
    const component_id = parseInt(params.component_id);
    const repeat_time = parseInt(params.repeat_time);
    const day_sequence = parseInt(params.day_sequence);
    const activity_id = parseInt(params.activity_id);
    const [finalAvgAccuracy, setfinalAvgAccuracy] = useState(0);
    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    const reset = (fun) => {
        // console.log("fun_1", resetRound)
        resetRound();
    }

    const startCountdown = () => {
        // console.log("count down started")
        startTimer();
    }

    useEffect(() => {
        togglePause();
    }, [])

    useEffect(() => {
        let accuracy;   
        accuracy = (((avgAccuracy * round) + accuracyCurrentRound) / (round + 1))
        setfinalAvgAccuracy(accuracy)
    }, [accuracyCurrentRound])

    const avgScore = () => {
        return (((avgAccuracy * round) + accuracyCurrentRound) / (round + 1))
    }
    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: component_id,
        day_sequence: day_sequence,
        repeat_time: repeat_time,
        activity_id: activity_id,
        status: status,
        activity_score: avgScore(),
        activity_play_time: ((totalTime * 60) - timeRemaining),
        activity_round_no: round + 1,
        total_avg_congruent_response_time: totalAvgCongruentResponseTime,
        total_avg_incongruent_response_time: totalAvgIncongruentResponseTime,
        total_avg_neutral_response_time: totalAvgNeutralResponseTime,
        total_avg_response_time: ((totalAvgCongruentResponseTime + totalAvgIncongruentResponseTime + totalAvgNeutralResponseTime) / (3))
    })
    useEffect(() => {
       if (timeRemaining > 0 && !free) {
            submitUserActivityData(payload)
        }

    }, [payload])
    const submitUserActivityData = async (payload_1) => {
            try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );

            // if (refreshToken) {
                const result = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
                    payload_1,
                    "",
                    ""
                );
                if (result) {
                    if (result.status === 200) {
                        // Handle status 200
                        // console.log("api_submit_result_1", result);
                    } else if (result.status === 203) {
                        // Handle status 203
                    }
                }
                setIsLoading(false);
            // }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }

    return (
        <>
            {
                (timeRemaining > 0) ?
                    <>
                        {(isLoading) ? <LazyLoader /> : null}
                        <section className="reaction-speed-section align-excutive">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="stepsin stpelog">
                                        <Link to="dashboard">
                                            <img src={logo} className="logo-steps" alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="btnNext-top">
                                        <Link to={`/executive-function-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`} >
                                            <button
                                                className="btn btn-primary reaction-speed-btnEsci"
                                            >
                                                {t("user.activity.reaction_speed.back")}
                                            </button>
                                        </Link>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="executive-function-resume">
                                            <h2>{t("user.activity.executive_function.resume_heading")}</h2>
                                            <p>{t("user.activity.executive_function.resume_message")}</p>
                                            <button className="btn btn-primary btnNext" onClick={event => { reset(); startCountdown() }}>{t("user.activity.executive_function.continue")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                    </>
                    :
                    <ExecutiveFunctionResult props={{ "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime, "totalAvgCongruentResponseTime": totalAvgCongruentResponseTime, "totalAvgIncongruentResponseTime": totalAvgIncongruentResponseTime, "totalAvgNeutralResponseTime": totalAvgNeutralResponseTime ,'free':free ,'maxSteps': maxSteps,'step':step,"accuracyCurrentRound":accuracyCurrentRound,"round":round}} />
            }
        </>
    )

}