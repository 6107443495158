import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import GroupPlus1 from "../../../../assets/Image/GroupPlus1.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from "../../../apiConnection/getRequest";
import NBackMovement from "./NBackMovementSteps.json";
import { useSpeechSynthesis } from "react-speech-kit";
import BWGroup1862 from "../../../../assets/Image/BWGroup1862.svg";
import NBackMovementResume from "./NBackMovementResume";
import LazyLoader from "../../../common/lazyLoader";


export const NBackMovementIndex = (props) => {

    const [t, i18n] = useTranslation("common");
    const [n, setN] = useState(2);
    const [steps, setSteps] = useState(0);
    const { speak } = useSpeechSynthesis();
    const history = useHistory();
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const [cases, setCases] = useState(null);
    const [position, setPosition] = useState();
    const [score, setScore] = useState(0);
    const [click, setClick] = useState(false);
    // const [round, setRound] = useState();
    const [activityId, setActivityId] = useState();
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [avgAccuracy, setAvgAccuracy] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [totalTime, setTotalTime] = useState(0);
    const [totalAccuracy, setTotalAccuracy] = useState(avgAccuracy);
    const [accuracyCurrentRound, setAccuracyCurrentRound] = useState(0);
    const [round, setRound] = useState(1);
    const [activityPlayTime, setActivityPlayTime] = useState(0);
    const [stepTimeRemaining, setStepTimeRemaining] = useState(0);
    const [activityStatus, setActivityStatus] = useState("");
    const [totalSteps, setTotalSteps] = useState(0);
    const [roundTime, setRoundTime] = useState(0);
    const [maxItemKeepMind, setMaxItemKeepMind] = useState(0);
    const [itemKeepMind, setItemKeepMind] = useState(0);
    const [activitiyMaxScore, setActivitiyMaxScore] = useState(0);
    const [userResponse, setUserResponse] = useState(false);
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
    const [free, setFree] = useState(props?.match?.params?.free);

    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])


    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        setTotalSteps(Math.floor(Math.random() * (32 - 25 + 1)) + 25);
    }, [])

    useEffect(() => {
        let timerId;
        if (!isPaused && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
                setRoundTime((prevTime) => prevTime + 1)
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timeRemaining, isPaused]);

    useEffect(() => {
        if (!free) {
            getActivityUserData();
        }
        else {
            setIsLoading(false);
            setTimeRemaining(300);
        }
    }, [])

    const getActivityUserData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            if (refreshToken) {
                const getUserActivityData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ACTIVITY_STATUS}?repeat_time=${repeat_time}&component_id=${component_id}&day_sequence=${day_sequence}&activity_id=${activity_id}`,
                    "",
                    "",
                    ""
                );

                if (getUserActivityData && getUserActivityData.status === 200) {
                    setIsLoading(false);
                    if (getUserActivityData?.data?.data?.activity_data) {
                        let dataObj = getUserActivityData?.data?.data?.activity_data;
                        setActivityId(dataObj.activity_id);
                        setActivityPlayTime(dataObj.activity_play_time);
                        setTotalTime(dataObj.activity_total_data[0].activity_duration);
                        setTimeRemaining(((dataObj.activity_total_data[0].activity_duration) * 60) - (dataObj.activity_play_time));
                        setRound(dataObj.activity_round_no);
                        setAvgAccuracy(dataObj.activity_score);
                        setActivityStatus(dataObj.status);
                        setActivitiyMaxScore(dataObj.activities_max_score);
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }


    useEffect(() => {
        if (n && !isLoading && !isPaused) {

            if (n == 2) {
                var caseNo = generateRandomNumber(0, 2);
            }

            if (n == 3) {
                var caseNo = generateRandomNumber(3, 5);
            }

            if (n == 4) {
                var caseNo = generateRandomNumber(6, 8);
            }

            let x = `case-${caseNo}`;
            setCases(NBackMovement[x]);
        }
    }, [n, isPaused, isLoading])


    function generateRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        setStepTimeRemaining(0);

        // if (!isLoading && !isPaused) {
        if (!isLoading && !isPaused && (steps <= totalSteps)) {
            const interval = setInterval(() => {
                setSteps(steps + 1);
                setUserResponse(false);

            }, 3000);
            return () => clearInterval(interval);
        }
    }, [steps, isLoading, isPaused]);

    useEffect(() => {
        if (steps <= totalSteps && cases && !isPaused) {
            // setClick(false);
            var x = `position-${steps}`;
            setPosition(cases[x]);
        }
    }, [steps])

    useEffect(() => {
        console.log("Steps", steps, "Score", score);
    }, [steps])


    const handleUserKeyPress = useCallback(event => {
        console.log("space bar clicked");
        const { key, keyCode } = event;
        if (keyCode === 32) {
            clickByUser();
        }
    })

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };

    }, [handleUserKeyPress]);




    const clickByUser = () => {
        if (steps > totalSteps) return;
        setClick(true);

        if (userResponse == false && (steps <= totalSteps)) {

            setUserResponse(true);

            if (n === 2) {
                if (steps > 2) {

                    let x = `position-${steps}`;
                    let y = `position-${steps - 2}`;

                    if (cases[x] === cases[y]) {
                        setScore(score + 1);
                        setItemKeepMind(itemKeepMind + 1);
                    } else {
                        setItemKeepMind(0);
                    }
                }
            }

            if (n === 3) {
                if (steps > 3) {

                    let x = `position-${steps}`;
                    let y = `position-${steps - 3}`;

                    if (cases[x] === cases[y]) {
                        setScore(score + 1);
                        setItemKeepMind(itemKeepMind + 1);
                    } else {
                        setItemKeepMind(0);
                    }
                }
            }

            //Condition first N=4
            if (n === 4) {
                if (steps > 4) {

                    let x = `position-${steps}`;
                    let y = `position-${steps - 4}`;

                    if (cases[x] === cases[y]) {
                        setScore(score + 1);
                        setItemKeepMind(itemKeepMind + 1);
                    } else {
                        setItemKeepMind(0);
                    }
                }
            }
        }
    }

    function unClickByUser() {

        //Condition first N=2
        if (n === 2) {
            if (steps > 2) {

                let x = `position-${steps - 1}`;
                let y = `position-${steps - 2 - 1}`;

                if (cases[x] !== cases[y]) {
                    setScore(score + 1);
                    setItemKeepMind(itemKeepMind + 1);
                } else {
                    setItemKeepMind(0);
                }
            }
        }

        //Condition first N=3
        if (n === 3) {
            if (steps > 3) {

                let x = `position-${steps - 1}`;
                let y = `position-${steps - 3 - 1}`;
                if (cases[x] !== cases[y]) {
                    setScore(score + 1);
                    setItemKeepMind(itemKeepMind + 1);
                } else {
                    setItemKeepMind(0);
                }
            }
        }

        //Condition first N=4
        if (n === 4) {
            if (steps > 4) {

                let x = `position-${steps - 1}`;
                let y = `position-${steps - 4 - 1}`;

                if (cases[x] !== cases[y]) {
                    setScore(score + 1);
                    setItemKeepMind(itemKeepMind + 1);
                } else {
                    setItemKeepMind(0);
                }
            }
        }
    }

    useEffect(() => {
        if (steps - 1 > totalSteps) return
        // if (!isPaused && (steps <= totalSteps)) {
        if (!isPaused && steps > n + 1 && (click == false)) {
            // checkResult();
            unClickByUser();
        }
        setClick(false);
    }, [steps])

    // const checkResult = () => {
    //     if (steps) {
    //         //Condition 1 User will click a value
    //         // if (value) {
    //         //     clickByUser(value);
    //         // }
    //         //Condition 2 User leave 
    //         if (!click) {
    //             unClickByUser();
    //         }
    //     }
    // }

    const resetRound = (accuracyLastRound, n) => {

        if (accuracyLastRound >= .6) {
            if (n == 4) {
                setN(n)
            } else {
                setN(n + 1);
            }
        } else if (accuracyLastRound <= .6 && accuracyLastRound >= .4) {
            setN(n)
        } else {
            if (n == 2) {
                setN(n)
            } else {
                setN(n - 1);
            }
        }

        setSteps(0);
        setScore(0);
        setRoundTime(0);
        setMaxItemKeepMind(0);
        setItemKeepMind(0);
        getActivityUserData();
    }

    const togglePause = () => {
        setIsPaused(!isPaused);

        if (free) {
            setTimeRemaining(0);
        }
    }

    const startTimer = () => {
        setIsPaused(false);
    }

    useEffect(() => {
        // if ((steps > 0) && (steps <= totalSteps)) {
        if ((steps > 0)) {
            setAccuracyCurrentRound((score / (totalSteps - n)));
        }
    }, [score])

    useEffect(() => {
        if (steps > 0) {
            // if (steps <= totalSteps) {
            avgScore();
        }
    }, [accuracyCurrentRound])

    const avgScore = () => {
        let accuracy;
        if (!free) {
            accuracy = (((avgAccuracy * round) + (accuracyCurrentRound)) / (round + 1));
        } else {
            accuracy = accuracyCurrentRound;
        }
        setTotalAccuracy(accuracy);
    }


    useEffect(() => {
        let timerId;

        // calculation of time

        // milliSecond   width
        //  2000         100%
        //  2000/100     1%
        //  20           1%
        //  500          25%
        if (!isLoading && steps) {

            if (stepTimeRemaining < 100) {
                timerId = setInterval(() => {
                    setStepTimeRemaining((prevTime) => prevTime + 25);
                }, 500);
            }

            return () => {
                if (timerId) {
                    clearInterval(timerId);
                }
            };
        }
    }, [stepTimeRemaining, isLoading, steps]);

    useEffect(() => {
        if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && !free) {
            history.push(`/n-back-movement-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`);
        }
        else if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && free) {
            history.push(`/n-back-movement-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`);
        }
    }, [activityStatus])

    useEffect(() => {

        if (itemKeepMind > maxItemKeepMind) {
            setMaxItemKeepMind(maxItemKeepMind + 1)
        }
    }, [itemKeepMind])

    useEffect(() => {
        if (maxItemKeepMind > activitiyMaxScore) {
            setActivitiyMaxScore(maxItemKeepMind)
        }
    }, [maxItemKeepMind])


    // const minutes = Math.floor(timeRemaining / 60);
    // const seconds = timeRemaining % 60;

    return (
        <>
            {steps <= totalSteps ?
                <>
                    {(isLoading) ? <LazyLoader /> : null}
                    <section className="reaction-speed-section n-back-section-mob">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="stepsin stpelog">
                                        <Link to="dashboard">
                                            <img src={logo} className="logo-steps" alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="btnNext-top">
                                        {free ?
                                            <Link to={`/n-back-movement-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                                <button
                                                    className="btn btn-primary  btnNext"
                                                >
                                                    {t("user.activity.reaction_speed.back")}
                                                </button>
                                            </Link>
                                            :
                                            <Link to={`/n-back-movement-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                <button
                                                    className="btn btn-primary  btnNext"
                                                >
                                                    {t("user.activity.reaction_speed.back")}
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="reaction-speed-p">
                                        <p className="reaction-speed-p-1">{t('user.activity.n_back_movement.intro_heading')}</p>
                                        <p className="reaction-speed-p-2">{t("user.activity.n_back_movement.info_message_1")} {n} {t("user.activity.n_back_movement.info_message_2")}</p>
                                        <div className="n-count">
                                            <span className="n-curr">{n}</span>
                                            {[...Array(n)].map((e, i) =>
                                                <span className={steps - 1 >= i ? 'active_dot' : 'dot'} key={i}></span>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="reaction-speed-step">
                                        <p>{steps}/{totalSteps}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="n-back-main">
                                        <div className="n-back-plus" style={{ display: steps > n ? 'flex' : 'none', justifyContent: "center", cursor: 'pointer', overflow: 'hidden' }}>
                                            <img src={GroupPlus1} alt="Icon" className="darkn-plus-icon" />
                                        </div>
                                        <div onClick={(e) => clickByUser()} style={{ display: "flex", justifyContent: "center", margin: 35, cursor: 'pointer' }}>
                                            <div className={`${position}`}></div>
                                        </div>
                                        <div className="n-back-plus" style={{ display: steps > n ? 'flex' : 'none', justifyContent: "center", cursor: 'pointer', overflow: 'hidden' }} >
                                            <img src={GroupPlus1} className="darkn-plus-icon" alt="Icon" />
                                        </div>
                                    </div>
                                    <div className="n-back-progress-main">
                                        <div className="n-back-progress-bar progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${stepTimeRemaining}%` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                :
                <NBackMovementResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "timeRemaining": timeRemaining, "avgAccuracy": totalAccuracy, "accuracyCurrentRound": accuracyCurrentRound, "params": props.match.params, "n": n, "startTimer": startTimer, "score": score, "roundTime": roundTime, "maxItemKeepMind": maxItemKeepMind, "activitiyMaxScore": activitiyMaxScore, "free": free }} />
            }
        </>
    )

}