import Sidebar from '../common/sidebar'
import { getLocalStoarge, setLocalStoarge } from '../common/allfunction';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import shape from "../../assets/Image/shape.svg";
import { FaEllipsisH } from 'react-icons/fa';
import CLOCK_ICONA from '../../assets/Image/CLOCK_ICONA.svg'
import GroupLock from '../../assets/Image/GroupLock.svg'
import PostRequest from '../apiConnection/postRequest';
import LazyLoader from '../common/lazyLoader';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from "../common/modal";
import Group769 from "../../assets/Image/Group769.svg";
import Group_841 from "../../assets/Image/Group_841.svg";
import Group756 from "../../assets/Image/Group769.svg";
import { Link, useParams } from "react-router-dom";
import MEDITAZIONE_ICONA from '../../assets/Image/MEDITAZIONE_ICONA.svg'
import REAZIONE_ICONA from '../../assets/Image/REAZIONE_ICONA.svg'
import moment from 'moment';
import GetRequest from '../apiConnection/getRequest';
import TRAINING from '../../assets/Image/TRAINING COGNITIVO_ICONA.svg'
import Group840 from '../../assets/Image/Group840.svg';
import CLOCK from '../../assets/Image/CLOCK.svg';
import Group8401 from '../../assets/Image/Group8401.svg'
import Group8402 from '../../assets/Image/Group8402.svg'
import tracks from './meditaionActivity/tracks';
import AudioPlayer from './meditaionActivity/AudioPlayer';
import imgSrc from "../user/meditaionActivity/assets/artwork.jpg";
import { file } from '@babel/types';
import Sidebarmob from '../common/sidebarMobile';
import { useHistory } from "react-router-dom";
import Group1804 from '../../assets/Image/Group1804.svg';
import bwGroup1409 from '../../assets/Image/bwGroup1409.svg';
import bwLine from '../../assets/Image/bwLine153.svg';
import audioCross from '../../assets/Image/audio-cross.svg';
import rightArrow from '../../assets/Image/rightArrow.svg'
import leftArrowIcon from '../../assets/Image/leftArrowIcon.svg'



export const MindFullNess = (props) => {
    const [t, i18n] = useTranslation("common");
    const [detail, setDetail] = useState(JSON.parse(getLocalStoarge('health_data')));
    const [isLoading, setIsLoading] = useState(true);
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [userActivityData, setUserActivityData] = useState([]);
    const [trainingPopup, setTrainingPopup] = useState(false);
    const [componentId, setComponentId] = useState(2);
    const [item, setItem] = useState(4);
    const [totalItem, setTotalItem] = useState();
    const [repeatation, setRepeatation] = useState(props.match.params.repeatTime);
    const [mindFulnessDate, setMindFulnessDate] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment().startOf('day').unix());
    const [daywiseData, setDaywiseData] = useState([])
    const [preAssessmentModal, setPreAssessmentModal] = useState(false);
    const [freeActivity, setFreeActivity] = useState(false);
    const [meditationPopup, setMeditationPopup] = useState(false);
    const [audioScreen, setAudioScreen] = useState(1);
    const [audioFile, setAudioFile] = useState([])
    const [activityTime, setActivityTime] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false);
    const [daySequence, setDaySequence] = useState(null);
    const [activityId, setActivityId] = useState(null);
    const [activityTotalTime, setActivityTotalTime] = useState(0)
    const [dateDiff, setDateDiff] = useState();
    const [isExpired, setIsExpired] = useState();
    const [incomplete, setIncomplete] = useState(true);
    const [param, setParam] = useState(props?.match?.params);
    const [popup, setPopup] = useState(props.match.params.open);
    const [intervalData, setIntervalData] = useState(null);
    const history = useHistory();
    const [startDate, setStartDate] = useState(null);
    const [activityName, setActivityName] = useState(null);
    const [activityDay, setActivityDay] = useState(null);
    const [audioCompletePopup, setAudioCompletePopup] = useState(false);
    const [audioFileTime, setAudioFileTime] = useState(null);
    const { repeatTime } = useParams();
    const [mindfullResult , setMindfullResult ] = useState([]);
    const [token, setToken] = useState(() => {
        const saved = localStorage.getItem("R1");
        const initialValue = JSON.parse(saved);
        return initialValue.access_token || "";
    });
    const mdfResult ='';
        const [freeActivityResult, setFreeActivityResult] = useState(localStorage.getItem('MindfullNess_Result_'+props?.match?.params?.repeatTime) ? JSON.parse(localStorage.getItem('MindfullNess_Result_'+props?.match?.params?.repeatTime)) : []);

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        if (!getLocalStoarge('MindfullNess_Result_' + repeatation)) {
            setLocalStoarge('MindfullNess_Result_' + repeatation, JSON.stringify([]));
        }
        setMindfullResult(JSON.parse(getLocalStoarge('MindfullNess_Result_' + repeatation)));
    }, [])

    useEffect(() => {
        if (!getLocalStoarge('MindfullNess_Result_' + repeatation)) {
            setLocalStoarge('MindfullNess_Result_' + repeatation, JSON.stringify([]));
        }
        setMindfullResult(JSON.parse(getLocalStoarge('MindfullNess_Result_' + repeatation)));
    }, [isPlaying])

    useEffect(() => {
            mindFulNessTrainingData(repeatTime);
        
    }, [repeatTime])

    useEffect(() => {
        if (isPlaying ) {
            let newInterval = setInterval(() => {
                setActivityTime((activityTime) => {
                    if(freeActivity == false){
                        if (activityTime % 60 === 0) {
                            saveUserActivityData(activityTime);
                        }
                    }
                    return Number(activityTime) + 1;
                });
            }, 1000);
            console.log('interval time ', activityTime)
            setIntervalData(newInterval);
        } else {
            clearInterval(intervalData);
        }
        return () => {
            clearInterval(intervalData);
        };
    }, [isPlaying]);


    const saveUserActivityData = async (activityTime, complete) => {
        const payload = {
            company_id: companyId,
            component_id: componentId,
            day_sequence: daySequence,
            repeat_time: +repeatation,
            activity_id: activityId,
            activity_play_time: activityTime
        };
        if (complete == 'complete') {
            payload.status = 'complete';
            payload.activity_play_time = audioFileTime
        } else {
            payload.status = ((activityTotalTime * 60 == activityTime + 1)) ? 'complete' : 'in-complete';
        }
        try {
            const getuserData = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_USER_ACTIVITY_DATA}`,
                payload,
                token,
                ""
            );
        } catch (error) {
            setIsLoading(false);
        }
    }


    const mindFulNessTrainingData = async (repeatTime) => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllCognitiveData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ALL_DAYWISE_TRAINING}?repeat_time=${repeatTime}&component_id=${componentId}&company_id=${companyId}`,
                );
                if (getAllCognitiveData && getAllCognitiveData.status === 200) {
                    setIsLoading(false);
                    let finalDatePre = getAllCognitiveData.data.data.user_all_activity_data[0].finalCompleteDate != '' ? getAllCognitiveData.data.data.user_all_activity_data[0].finalCompleteDate : ''
                    const dateDifference = moment(moment.unix(currentDate).format("YYYY-MM-DD")).diff(moment(moment.unix(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date).format("YYYY-MM-DD")), 'days')
                    setDateDiff(moment(moment.unix(currentDate).format("YYYY-MM-DD")).diff(moment(moment.unix(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date).format("YYYY-MM-DD")), 'days') + 1)
                    setStartDate(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date)
                    const originalDate = moment.unix(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date);

                    //postAssessment start date resultTimestamp
                    // const newDate = originalDate.add(55, 'days');// if you open the post asssisment on 56 day the add day is 55.
                    const newDate = originalDate.add(89, 'days');// if you open the post asssisment on 56 day the add day is 55.

                    const postAssissmentStartDate = newDate.unix();


                    //postAssessment end date resultTimestamp
                    // const finalDate = newDate.add(15, 'days');
                    const finalDate = newDate.add(10, 'days');

                    const postAssissmentEndDate = finalDate.unix();


                    if (getAllCognitiveData.data.data?.user_all_activity_data.length > 0) {
                        let arr = [];
                        let card = [];
                        let isPostAssessment = 0;
                        let finalDiff = [];
                        let postAssessmentButton = false
                        card[0] = 1;
                        finalDiff[0] = 2;
                        setLocalStoarge("post", getAllCognitiveData.data.data.user_all_activity_data[29].allActivityComplete);
                        let activityLength = getAllCognitiveData.data.data.user_all_activity_data.length;
                        getAllCognitiveData.data.data.user_all_activity_data.forEach((data, i) => {
                            let completed = 0;
                            data.status.forEach((d1) => {
                                if (d1 === 'complete') {
                                    completed++
                                }
                            })
                            if (completed == data.status.length) {
                                setIncomplete(false)
                            }
                            let totalTime = 0;
                            data.activities_total_time.forEach((t1) => {
                                totalTime += t1
                            })
                            let particularActivityTime = 0;
                            data.activities_play_time.forEach((t2) => {
                                particularActivityTime += Math.floor(t2)
                            })
                            card[i + 1] = data.allActivityComplete;
                            finalDiff[i + 1] = data.finalCompleteDate != '' ? moment(moment.unix(currentDate).format("YYYY-MM-DD")).diff(moment(moment.unix(data.finalCompleteDate).format("YYYY-MM-DD")), 'days') + 1 : 0;


                            if (getAllCognitiveData.data.data.user_all_activity_data[28].allActivityComplete === 0) {
                                if (getAllCognitiveData.data.data.user_all_activity_data[0].allActivityComplete === 1) {
                                    if (postAssissmentStartDate <= currentDate && postAssissmentEndDate >= currentDate) {
                                        isPostAssessment = 1;
                                        card[i] = 1;
                                        finalDiff[i] = 2;
                                        postAssessmentButton = true;
                                  
                                    // } else if (getAllCognitiveData.data.data.user_all_activity_data[29].allActivityComplete === 1 || dateDifference > 71) {
                                    } else if (getAllCognitiveData.data.data.user_all_activity_data[29].allActivityComplete === 1 || dateDifference > 101) {
                                        isPostAssessment = 1;
                                        card[i] = 1;
                                        finalDiff[i] = 2;
                                        postAssessmentButton = true;
                                    }
                                }
                            }else if(getAllCognitiveData.data.data.user_all_activity_data[28].allActivityComplete === 1){
                                postAssessmentButton=true
                            }
                            // if (dateDifference >= 71) {
                            if (dateDifference >= 101) {
                                isPostAssessment = 1;
                                card[i] = 1;
                                finalDiff[i] = 2;
                                postAssessmentButton = true;
                            }

                            arr = [...arr, {
                                user_id: data.user_id,
                                activities_score: data.activities_score || "",
                                activity_ids: data.activity_ids,
                                day_sequence: data.day_sequence || "",
                                type: data.type,
                                repeat_time: data.repeat_time,
                                status: data.status,
                                company_id: data.company_id,
                                component_id: data.component_id,
                                activities_round_no: data.activities_round_no,
                                activities_play_time: data.activities_play_time,
                                activities_total_time: data.activities_total_time,
                                activity_names: data.activity_names,
                                start_training_date: data.start_training_date,
                                activityCompleted: completed,
                                activityTotalTime: totalTime,
                                particularActivityTime: particularActivityTime,
                                cardEnable: card[i],
                                finalDiff: finalDiff[i],
                                allActivityComplete: data.allActivityComplete,
                                isPostAssessment: isPostAssessment,
                                postAssissmentStartDate,
                                postAssissmentEndDate,
                                postAssessmentButton

                            }]
                        })
                        setUserActivityData([]);
                        setUserActivityData(prevData => (arr));
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const startActivity = (componentId, day_sequence, repeatTime) => {
        setIsLoading(true)
        setPreAssessmentModal(true);
        getDaywiseActivityData(componentId, day_sequence, repeatTime)
    }
    const startFreeActivity = (componentId, day_sequence, repeatTime) => {
        setIsLoading(true)
        setPreAssessmentModal(true);
        setFreeActivity(true);
        getDaywiseActivityData(componentId, day_sequence, repeatTime)
    }

    const progressVal = (activityStatus, allActivity,free) => {
        let result = 0
        if (Array.isArray(activityStatus)) {
        for (let i of activityStatus) {
            if (i === 'complete') {
                result++
            }

        }
        }else if (activityStatus === 'complete') {
            result++;
        }
        let finalScore = Math.floor(result * 100 / allActivity)
                return finalScore       
    }

    const progressValMindFulness = (activityTime, activityTotalTime) => {
        let result = 0;
        const sumWithInitial = activityTime.reduce(
            (first, second) => first + second,
            result,
        );
        let allTime = 0;
        const sumWithlast = activityTotalTime.reduce(
            (first, second) => first + second,
            allTime,
        );
        let finalScore = Math.floor((sumWithInitial) * 100 / sumWithlast)
        return finalScore
    }

    function getProgressBarColorClass(score) {
        if (score === 0) {
            return 'progress-bar-color-0';
        } else if (score <= 25) {
            return 'progress-bar-color-25';
        } else if (score <= 50) {
            return 'progress-bar-color-50';
        } else if (score <= 75) {
            return 'progress-bar-color-75';
        } else {
            return 'progress-bar-color-100';
        }
    }


    const getActivityTime = (allActivityTime) => {
        let allTime = 0
        for (let i = 0; i < allActivityTime?.length; i++) {
            allTime += allActivityTime[i]
        }
        return allTime
    }

    const handleModel = (activityId, activityTime, activityDay, activityName) => {

        setActivityDay(activityDay);
        setActivityName(activityName);
        setActivityTotalTime(activityTime * 60);
        setActivityId(activityId);
        setPreAssessmentModal(false);
        getAudioTraining(activityId);
        console.log('handle model')
    }

    const getAudioTraining = async (activityId) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getMeditationData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_MEDITATION_ACTIVITY}?activity_id=${activityId}`
                );
                setIsLoading(false)
                if (getMeditationData && getMeditationData.status === 200) {
                    let intervalData
                    let arr = [];
                    setAudioFileTime(getMeditationData?.data?.data?.audioFileData?.audioFileDuration)
                    const filePath = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_AUDIO_PATH}/${getMeditationData?.data?.data?.audioFileData.audioFile}`
                    arr.push({
                        title: "Sample",
                        artist: "Testing",
                        audioSrc: filePath,
                        image: imgSrc,
                        color: "#00aeb0"
                    })
                    setAudioFile(arr)
                    // setAudioFile(getMeditationData.data.data)
                    // tracks[0].audioSrc=getMeditationData?.data?.data.audioFile
                    setMeditationPopup(true);
                    meditationActivity()

                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }

    const getDaywiseActivityData = async (componentId, day_sequence, repeatTime) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDaywiseActivity = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARTICULAR_DAYWISE_TRAINING}?component_id=${componentId}&day_sequence=${day_sequence}&repeat_time=${repeatTime}`,
                );
                setIsLoading(false)
                if (getDaywiseActivity && getDaywiseActivity.status === 200) {
                    setDaywiseData(getDaywiseActivity?.data?.data?.daywise_plan)
                    setDaySequence(getDaywiseActivity?.data?.data?.daywise_plan.day_sequence)
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const closeModal = () => {
        setPreAssessmentModal(false);
        setDaywiseData([]);
        setIsPlaying(false);
        setAudioCompletePopup(false);
        mindFulNessTrainingData(repeatTime);
        setActivityTime(0);

    }

    const audioSubmit = (complete) => {
        if (freeActivity == true) {
            let result;
            if (getLocalStoarge('MindfullNess_Result_' + repeatation).length > 0) {
                result = JSON.parse(getLocalStoarge('MindfullNess_Result_' + repeatation));
            }
            result[daySequence] = {
                activity_play_time: (activityTime * 60),
                activity_status: complete
            }
            setLocalStoarge('MindfullNess_Result_' + repeatation, JSON.stringify(result));
        }
        else {
            saveUserActivityData((activityTime * 60), complete);
        }

        setMeditationPopup(false);
        setAudioCompletePopup(true);
    }

    const closeAudioModal = () => {
        setMeditationPopup(false);
        setPreAssessmentModal(true);
        if (freeActivity == true) {
            console.log('submit in localStorage');
            setIsLoading(true)
            let result;
            if (getLocalStoarge('MindfullNess_Result_' + repeatation).length > 0) {
                result = JSON.parse(getLocalStoarge('MindfullNess_Result_' + repeatation));
            }
            result[daySequence] = {
                activity_play_time: (activityTime * 60),
                activity_status: 'complete'
            }
            setLocalStoarge('MindfullNess_Result_' + repeatation, JSON.stringify(result));
        }
        setIsPlaying(false);
        setActivityTime(0);
        setFreeActivity(false);
        setIsLoading(false)
    }

    const getActiveCard = (userActivityData) => {
        let active = 0;
        userActivityData.some((item) => {
            if (item.cardEnable === 1 && item.finalDiff > 1) {
                active++
            }
        })
        return active
    }



    const meditationActivity = () => {
        return (
            <div className='audio-player-model'>
                <div className='audio-close-btn'>
                    <img src={audioCross} alt="icon" className='p-2' onClick={() => closeAudioModal()} />
                </div>
                <AudioPlayer tracks={audioFile}
                    startStop={setIsPlaying}
                    setModelClose={audioSubmit}
                    audioDuration={setActivityTime}
                />
            </div>
        )
    }

    const audioComplete = () => {
        return (
            <div className="model-pop-new " >
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className='d-flex'>
                                <div className="ico-background">
                                    <img src={bwGroup1409} alt="icon" className="model-icon-mindfullness ico-color" />
                                </div>
                                <strong>{t('user.cognitive_training.day')} {activityDay} / {activityName}</strong>
                            </div>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { closeModal() }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">

                        <div className='row p-5'>
                            <div className="col-lg-6 p-1">
                                <span className='audio-min'><strong>{Math.floor(audioFileTime)}</strong></span><span className='audio-min-min'>min</span> <br />
                                <img src={bwLine} alt="" />
                            </div>
                            <div className="col-lg-6 p-1">
                                <p dangerouslySetInnerHTML={{ __html: t('user.activity.audio-activity.complete_thought') }}></p>
                            </div>
                        </div>
                        <p className='audio-complete-btn'>
                            <button type='button' className='start-training' onClick={() => { closeModal() }}>{t('user.activity.reaction_speed.exit')}</button>
                        </p>
                    </div>
                </div>
            </div>
        )
    }


    const modal1Content = () => {
        return (
            <>
                <div className="model-pop-new " >
                    <div className="model-pop ">
                        <div className="modal-header">
                            <h5 className="modal-title pop_heading" id="exampleModalLabel">
                                <div className='d-flex'>
                                    <div className="mindfulness-ico-background">
                                        <img src={MEDITAZIONE_ICONA} alt="icon" className="model-icon-mindfullness" />
                                    </div>
                                    {(daywiseData.day_sequence == 1) ? <strong>{t("user.cognitive_training.cognitive-model-heading")}</strong> : (daywiseData.day_sequence > 1 || daywiseData.day_sequence < 30) ? <strong>{t("user.cognitive_training.heading_1")} {daywiseData.day_sequence}</strong> : (daywiseData.day_sequence == 30) ? <strong>{t("user.cognitive_training.post_Assessment")}</strong> : ''}
                                </div>
                            </h5>
                            <button
                                type="button"
                                className="close r0-side"
                                onClick={() => { closeModal() }}
                            >
                                <img src={Group769} alt="icon" />
                            </button>
                        </div>
                        <div></div>
                        <div className="modal-body scroll-pop scroll-pop-new">
                            <div className='row'>
                                {daywiseData && daywiseData.activity_ids?.length > 0 ? daywiseData?.activity_ids?.map((activity, index) => (
                                    <div className='col-sm-6 pt-2 pb-3 d-flex' key={index}>
                                        <div className='first-training'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <div className='activity-card'>
                                                            <img src={REAZIONE_ICONA} alt="" />
                                                            <span><strong>{activity.activity_name}</strong></span>
                                                        </div>
                                                    </div>
                                                    {(daywiseData.day_sequence > 1 && daywiseData.day_sequence < 30) ? <div>
                                                        <p className='activity-min'>{activity.activity_duration}MIN</p>
                                                    </div> : null}

                                                </div>
                                                <p className='activity-discription'>{activity.activity_description}</p></div>
                                            <div>
                                                <p className='activity-model-btn'>
                                                    {((daywiseData.day_sequence === 1 && activity.status === 'in-complete') || (daywiseData.day_sequence === 30 && activity.status === 'in-complete')) ?

                                                        <Link to={`/preassessment/${activity.component_id}/${repeatTime}/${daywiseData.day_sequence}/${activity.id}`}>
                                                            <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                        </Link>

                                                        // <button type='button' className='start-training' onClick={() => window.location.href = `/app/preassessment/${activity.component_id}/${repeatation}/${daywiseData.day_sequence}/${activity.id}`}>{t('user.cognitive_training.start')}</button>

                                                        : (activity.status === 'in-complete') ? <button type='button' className='start-training' onClick={() => handleModel(activity.id, activity.activity_duration, daywiseData.day_sequence, activity.activity_name)}>{t('user.cognitive_training.start')}</button> :
                                                            (activity.status === 'complete' && freeActivity != true) ? <p className='complete-right'><button type='button' className='complete-btn' >{t('user.cognitive_training.complete')}</button></p> : (freeActivity === true) ? <button type='button' className='start-training' onClick={() => handleModel(activity.id, activity.activity_duration, daywiseData.day_sequence, activity.activity_name)}>{t('user.cognitive_training.start')}</button> : ""
                                                    }
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                )) : ''}

                            </div>

                        </div>
                    </div>
                </div>
                {isLoading ? <LazyLoader /> : ''}
            </>
        );
    };

    const option = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 4,
                nav: true,
                loop: false
            }
        }
    }

    return (
        <>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar repeatTime={repeatation} type={'mindfulness'} />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <h2 className="heading-dash">
                                    {t("user.cognitive_training.mind_fullness")}
                                </h2>

                                <div className="align-j">
                                    <div className='heading-select theme-color'>
                                        <strong>{t("user.cognitive_training.daily_activity")} </strong>
                                    </div>
                                    <div className="righ-side mt-5 mt-sm-0">
                                        <div className="logo-shape">
                                            <img src={shape} alt="icon" />
                                            <span>
                                                {detail.HEALTH_SCORE <= 20 ? (
                                                    <p className="">
                                                        {t("user.dashboard.buono")}
                                                    </p>
                                                ) : detail.HEALTH_SCORE >= 21 &&
                                                    detail.HEALTH_SCORE <= 40 ? (
                                                    <p className="">
                                                        {t("user.dashboard.buono1")}
                                                    </p>
                                                ) : detail.HEALTH_SCORE >= 41 &&
                                                    detail.HEALTH_SCORE <= 60 ? (
                                                    <p className="">
                                                        {t("user.dashboard.buono2")}
                                                    </p>
                                                ) : detail.HEALTH_SCORE >= 61 &&
                                                    detail.HEALTH_SCORE <= 80 ? (
                                                    <p className="">
                                                        {t("user.dashboard.buono3")}
                                                    </p>
                                                ) : detail.HEALTH_SCORE >= 81 &&
                                                    detail.HEALTH_SCORE <= 100 ? (
                                                    <p className="">
                                                        {t("user.dashboard.buono4")}
                                                    </p>
                                                ) : (
                                                    <span className=""></span>
                                                )}
                                            </span>
                                        </div>
                                        <div className="w80">
                                            {detail.HEALTH_SCORE
                                                ? parseFloat(detail.HEALTH_SCORE)
                                                : "0"}
                                        </div>
                                    </div>
                                </div>
                                <div className="position-relative">
                                    {userActivityData && userActivityData?.length > 0 ?
                                        <OwlCarousel {...option} navText={[`<img src=${leftArrowIcon} alt="" className='mr-1' />`, `<img src=${rightArrow} alt="" className='mr-1' />`]} startPosition={0} items={4} dots={false} slideBy={4} className='owl-theme' loop={false} margin={10} nav={true}>
                                            {userActivityData?.map((userActivity, i) => (
                                                <>
                                                    {(dateDiff >= userActivity.day_sequence && userActivity.cardEnable == 1 && userActivity.finalDiff > 1) ? <>
                                                        <div className='item' key={i}>
                                                            <div className='d-flex card-top'>
                                                                <div className='img-heading'>
                                                                    <div className={(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? `bg-icon-today mr-3` : 'mr-3'}>
                                                                        {(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? <img src={MEDITAZIONE_ICONA} alt="icon" className="new-sidebar-icon" /> : <img src={Group1804} alt="icon" className="new-sidebar-icon" />}
                                                                    </div>
                                                                    {(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? <h6 className='activity-name-font mb-0'>{t("user.cognitive_training.today")}</h6> : <h6 className='activity-name-font mb-0'>{t("user.cognitive_training.heading_1")} {userActivity.day_sequence}</h6>}
                                                                </div>
                                                                <div>
                                                                    {/* <i className="colorNew visi-none">
                                                                    {" "}
                                                                    <FaEllipsisH
                                                                        className="pointer"
                                                                    />
                                                                </i> */}
                                                                </div>
                                                            </div>
                                                            {userActivity && userActivity.activity_names.map((activityName, index) => (
                                                                <>
                                                                    <div className='pl-3 pr-3'>
                                                                        {(userActivity.day_sequence == 1 || userActivity.day_sequence == 2 || userActivity.day_sequence == 30) ? <div className='d-flex'><p className='span-number mr-1 mb-0'>{`${index + 1}`}</p> <p className='activityName m-0'>/ {activityName}</p></div>
                                                                            : (userActivity.day_sequence > 2 && userActivity.day_sequence <= 8) ?
                                                                                <><p className='span-number m-0'>{t('user.cognitive_training.basic')}<span className='slash'>/</span></p>
                                                                                    <p className='activityName m-0'>{activityName}</p></>
                                                                                : (userActivity.day_sequence > 8 && userActivity.day_sequence <= 14) ?
                                                                                    <><p className='span-number m-0'>{t('user.cognitive_training.intermediate')}<span className='slash'>/</span></p>
                                                                                        <p className='activityName m-0'>{activityName}</p></>
                                                                                    : (userActivity.day_sequence > 14 && userActivity.day_sequence <= 20) ?
                                                                                        <><p className='span-number m-0'>{t('user.cognitive_training.advanced')}<span className='slash'>/</span></p>
                                                                                            <p className='activityName m-0'>{activityName}</p></>
                                                                                        : (userActivity.day_sequence > 20 && userActivity.day_sequence <= 26) ?
                                                                                            <><p className='span-number m-0'>{t('user.cognitive_training.expert')}<span className='slash'>/</span></p>
                                                                                                <p className='activityName m-0'>{activityName}</p></>
                                                                                            : <p className='activityName m-0'>{activityName}</p>}

                                                                    </div>
                                                                </>
                                                            ))}

                                                            <div className='d-flex justify-content-between align-items-center mt-2 pl-3 pr-3 theme-text-color'>
                                                                <div>
                                                                    <span className='activity-id-name'>{userActivity.activity_ids.length} {t("user.cognitive_training.activity")}</span>
                                                                </div>
                                                                {userActivity.day_sequence >= 2 && userActivity.day_sequence <= 29 ? <div className='clock'>
                                                                    <span className="clock-img"><img src={CLOCK_ICONA} alt="" className='mr-1' /></span>
                                                                    <span className='activity-id-name'>{getActivityTime(userActivity.activities_total_time)} {t("user.cognitive_training.time")}</span>

                                                                </div> : ''}
                                                            </div>

                                                            {(userActivity.day_sequence == 1) ? <><p className='assisment pl-3 mb-0 mt-3 pr-3'>{t("user.cognitive_training.pre_Assessment")}</p>
                                                                <div className='pl-3 pr-3'>
                                                                    <div className="congnative-progress progress">
                                                                        <div className={`progress-bar ${getProgressBarColorClass(progressVal(userActivity.status, userActivity.activity_ids.length))}`} role="progressbar" style={{
                                                                            width: `${progressVal(userActivity.status, userActivity.activity_ids.length)}%`,
                                                                        }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                {incomplete === false ? <p className='progress-number'>{`${userActivity.activityCompleted}/${userActivity.activity_ids.length}`} {t("user.cognitive_training.answer")}</p> : <p className='progress-number'>{`${progressVal(userActivity.status, userActivity.activity_ids.length)}%`} {t("user.cognitive_training.progress")}</p>}

                                                            </> : ''}
                                                            {(userActivity.day_sequence == 30) ? <><p className='assisment pl-3 mb-1 mt-3 pr-3'>{t("user.cognitive_training.post_Assessment")}</p>
                                                                <div className='pl-3 pr-3'>
                                                                    <div className="congnative-progress progress">
                                                                        <div className={`progress-bar ${getProgressBarColorClass(progressVal(userActivity.status, userActivity.activity_ids.length))}`} role="progressbar" style={{
                                                                            width: `${progressVal(userActivity.status, userActivity.activity_ids.length)}%`,
                                                                        }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                {incomplete === false ? <p className='progress-number'>{`${userActivity.activityCompleted}/${userActivity.activity_ids.length}`} {t("user.cognitive_training.answer")}</p> : <p className='progress-number'>{`${progressVal(userActivity.status, userActivity.activity_ids.length)}`} {t("user.cognitive_training.progress")}</p>}

                                                            </> : ''}

                                                            <div className='mt-3 pl-3 pr-3'>
                                                                {userActivity.day_sequence >= 2 && userActivity.day_sequence <= 29 ? <>
                                                                    <p className='assisment mb-0 mt-2'>{t('user.cognitive_training.mind_fullness')}</p>
                                                                    {userActivity.activities_total_time && (
                                                                        <div className='pt-1'>
                                                                            <div className="congnative-progress progress">
                                                                                <div className={`progress-bar ${getProgressBarColorClass(progressValMindFulness(userActivity.activities_play_time, userActivity.activities_total_time))}`} role="progressbar" style={{ width: `${progressValMindFulness(userActivity.activities_play_time, userActivity.activities_total_time)}%` }} aria-valuenow={progressValMindFulness(userActivity.activities_play_time, userActivity.activities_total_time)} aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                            {incomplete === false ? <p className='progress-number-day'>{`${Math.floor(userActivity.particularActivityTime)}/${Math.floor(userActivity.activityTotalTime)}`} {t("user.cognitive_training.minute")}</p> : <p className='progress-number-day'>{`${progressVal(userActivity.status, userActivity.activity_ids.length)}%`} {t("user.cognitive_training.progress")}</p>}
                                                                        </div>
                                                                    )}
                                                                </> : ''
                                                                }
                                                            </div>
                                                            {(userActivity.allActivityComplete === 0) && (userActivity.day_sequence < 30) && (userActivity.postAssessmentButton === false) && (userActivity.postAssissmentEndDate >= currentDate) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => startActivity(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button>
                                                       
                                                            {/* </div> : (dateDiff <= 71) && (userActivity.day_sequence === 30) && (userActivity.allActivityComplete === 0) && (userActivity.postAssessmentButton) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => startActivity(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button> */}
                                                            </div> : (dateDiff <= 101) && (userActivity.day_sequence === 30) && (userActivity.allActivityComplete === 0) && (userActivity.postAssessmentButton) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => startActivity(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button>
                                                            </div> : null}
                                                            {/* {(dateDiff === userActivity.day_sequence) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => startActivity(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button>
                                                        </div> : ''} */}
                                                        </div>
                                                    </> : <div className='item-lock' key={i}>
                                                        <p className='img-lock-card card-top'><span className='ml-1'><img src={Group1804} alt="" /></span><span className='m-1'>{t('user.cognitive_training.day')}</span><span>{userActivity.day_sequence}</span></p>
                                                        <div className='lock-card p-3'>
                                                            <div className='lock-image-section'>
                                                                <img src={GroupLock} alt="" className='img-lock' />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </>

                                            ))}

                                            {(userActivityData[29]?.allActivityComplete === 1) ? <div className='item'>
                                                <div className='d-flex p-3'>
                                                    <div className='img-heading'>
                                                        <div className="ico-background">
                                                            <img src={MEDITAZIONE_ICONA} alt="icon" className="new-sidebar-icon" />

                                                        </div>
                                                        <h6>{t("user.cognitive_training.today")}</h6>

                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mt-2 p-2'>
                                                    <div>

                                                    </div>
                                                    <div className='clock'>


                                                    </div>
                                                </div>

                                                <h4 className='report-heading p-2 mt-3 mb-0'>Report</h4>
                                                <p className='text-left p-2 pt-0 m-0'>{t("user.activityReport.description_mindfulness")}</p>

                                                <div className='report-btn'>
                                                    <Link to={`/report-mindfulness/${componentId}/${repeatTime}`}>
                                                        <button type='button' className='start-btn'>{t("user.activityReport.view")}</button>
                                                    </Link>

                                                </div>

                                            </div> : null}

                                        </OwlCarousel>
                                        : ''}
                                         <p className='total-item'>{userActivityData && userActivityData?.length > 0 ? `${getActiveCard(userActivityData)}/${(userActivityData?.length)}` : null}</p>

                                </div>

                               {/* Free activity */}
                                <div className='mt-2 mb-5'>
                                    <p className='text-left theme-color'><strong>{t("user.cognitive_training.free")}</strong></p>
                                    <div className="position-relative">
                                    {userActivityData && userActivityData?.length > 0 ?
                                        <OwlCarousel {...option} navText={[`<img src=${leftArrowIcon} alt="" className='mr-1' />`, `<img src=${rightArrow} alt="" className='mr-1' />`]} startPosition={0} items={4} dots={false} slideBy={4} className='owl-theme' loop={false} margin={10} nav={true}>
                                            {userActivityData?.map((userActivity, i) => (
                                                <>
                                                    {(userActivity.day_sequence > 2 && userActivity.day_sequence < 30) ?
                                                        <div className='item' key={i}>
                                                           
                                                            <div className='d-flex card-top'>
                                                                <div className='img-heading'>
                                                                    <div className={(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? `bg-icon-today mr-3` : 'mr-3'}>
                                                                        {(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? <img src={MEDITAZIONE_ICONA} alt="icon" className="new-sidebar-icon" /> : <img src={Group1804} alt="icon" className="new-sidebar-icon" />}
                                                                    </div>
                                                                    {/* {(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? <h6 className='activity-name-font mb-0'>{t("user.cognitive_training.today")}</h6> : <h6 className='activity-name-font mb-0'>{t("user.cognitive_training.heading_1")} {userActivity.day_sequence}</h6>} */}
                                                                </div>
                                                                <div>
                                                                    {/* <i className="colorNew visi-none">
                                                                    {" "}
                                                                    <FaEllipsisH
                                                                        className="pointer"
                                                                    />
                                                                </i> */}
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                            {userActivity && userActivity.activity_names.map((activityName, index) => (
                                                                <>
                                                                    <div className='pl-3 pr-3'>
                                                                        {(userActivity.day_sequence == 1 || userActivity.day_sequence == 2 || userActivity.day_sequence == 30) ? <div className='d-flex'><p className='span-number mr-1 mb-0'>{`${index + 1}`}</p> <p className='activityName m-0'>/ {activityName}</p></div>
                                                                            : (userActivity.day_sequence > 2 && userActivity.day_sequence <= 8) ?
                                                                                <><p className='span-number m-0'>{t('user.cognitive_training.basic')}<span className='slash'>/</span></p>
                                                                                    <p className='activityName m-0'>{activityName}</p></>
                                                                                : (userActivity.day_sequence > 8 && userActivity.day_sequence <= 14) ?
                                                                                    <><p className='span-number m-0'>{t('user.cognitive_training.intermediate')}<span className='slash'>/</span></p>
                                                                                        <p className='activityName m-0'>{activityName}</p></>
                                                                                    : (userActivity.day_sequence > 14 && userActivity.day_sequence <= 20) ?
                                                                                        <><p className='span-number m-0'>{t('user.cognitive_training.advanced')}<span className='slash'>/</span></p>
                                                                                            <p className='activityName m-0'>{activityName}</p></>
                                                                                        : (userActivity.day_sequence > 20 && userActivity.day_sequence <= 26) ?
                                                                                            <><p className='span-number m-0'>{t('user.cognitive_training.expert')}<span className='slash'>/</span></p>
                                                                                                <p className='activityName m-0'>{activityName}</p></>
                                                                                            : <p className='activityName m-0'>{activityName}</p>}

                                                                    </div>
                                                                </>
                                                            ))}

                                                            <div className='d-flex justify-content-between align-items-center mt-2 pl-3 pr-3 theme-text-color'>
                                                                <div>
                                                                    <span className='activity-id-name'>{userActivity.activity_ids.length} {t("user.cognitive_training.activity")}</span>
                                                                </div>
                                                                <div className='clock'>
                                                                    <span className="clock-img"><img src={CLOCK_ICONA} alt="" className='mr-1' /></span>
                                                                    <span className='activity-id-name'>{getActivityTime(userActivity.activities_total_time)} {t("user.cognitive_training.time")}</span>

                                                                </div>
                                                            </div>
                                                            <p className='assisment pl-3 mb-0 mt-3 pr-3'></p>
                                                            <div className='pl-3 pr-3'>
                                                                <div className="congnative-progress progress">
                                                                  <div className={`progress-bar ${getProgressBarColorClass(progressVal( mindfullResult[userActivity.day_sequence]!=undefined ? mindfullResult[userActivity.day_sequence].activity_status : 'in-complete' , userActivity.activity_ids.length,'free'))}`} role="progressbar"
                                                                     style={{
                                                                        width: `${progressVal(mindfullResult[userActivity.day_sequence]!=undefined ? mindfullResult[userActivity.day_sequence].activity_status : 'in-complete' , userActivity.activity_ids.length,'free')}%`,
                                                                    }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='btn-start'><button type='button' className='start-btn' onClick={() => startFreeActivity(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}> { mindfullResult[userActivity.day_sequence]!=undefined ? t("superadmin.protocol.try_again_btn") :  t("user.cognitive_training.start")}</button>
                                                            </div>
                                                            {/* {(dateDiff === userActivity.day_sequence) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => startActivity(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button>
                                                        </div> : ''} */}
                                                        </div>
                                                        : null}
                                                </>

                                            ))}

                                            

                                        </OwlCarousel>
                                        : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}

                    <Modal title="" size="sm" contentClasses="training-model-popup" onClose={() => setPreAssessmentModal(false)} show={preAssessmentModal}>
                        {modal1Content()}
                    </Modal>
                    {/* <Modal title="" size="lg" contentClasses="" onClose={() => setMeditationPopup(false)} show={meditationPopup}>
                    {meditationActivity()}
                </Modal> */}
                    <Modal title="" show={meditationPopup} contentClasses="audio-modal-style" onClose={() => setMeditationPopup(false)}>
                        {meditationActivity()}
                    </Modal>

                    <Modal title="" size="sm" show={audioCompletePopup} contentClasses="training-model-popup" onClose={() => setAudioCompletePopup(false)}>
                        {audioComplete()}
                    </Modal>



                </div>
            </section>
            <div className="act-ve">
                <Sidebarmob />
            </div>
        </>
    )
}
